// This module defines a custom "pre" node for the TipTap editor.
// It wraps content in a `<pre>` block and provides a command to toggle 
// between a `<pre>` block and a `<p>` tag.

import { Node } from "@tiptap/core";

export default Node.create({
  name: "pre",
  group: "block",
  content: "text*",
  parseHTML() {
    return [{ tag: "pre" }];
  },
  renderHTML() {
    return ["pre", 0];
  },
  addCommands() {
    return {
      togglePre: () => ({ commands }) => commands.toggleNode(this.name, "paragraph"),
    };
  },
});
