// This module defines a custom "del" mark for the TipTap editor.
// It allows users to apply strikethrough formatting to selected text using the <del> HTML tag.
// The module includes the logic for parsing and rendering the "del" mark in HTML, 
// as well as a command to toggle the "del" formatting (strikethrough).

import { Mark } from "@tiptap/core";

export default Mark.create({
  name: "del",
  parseHTML() {
    return [{ tag: "del" }];
  },
  renderHTML() {
    return ["del", 0];
  },
  addCommands() {
    return {
      toggleDel: () => ({ commands }) => commands.toggleMark(this.name),
    };
  },
});
