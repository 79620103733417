import { Controller } from "@hotwired/stimulus";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import Image from "@tiptap/extension-image";
import TextStyle from "@tiptap/extension-text-style";
import Link from "@tiptap/extension-link";

import DelMark from "../lib/tiptap/marks/del_mark";
import PreNode from "../lib/tiptap/nodes/pre_node";
import { setLink } from "../lib/tiptap/extensions/link";
import { uploadImage } from "../lib/tiptap/extensions/image_upload";

export default class extends Controller {
  static targets = [
    "editor",
    "input",
    "boldButton",
    "italicButton",
    "strikeButton",
    "linkButton",
    "linkDropdown",
    "linkInput",
    "setLinkButton",
    "unsetLinkButton",
    "headingButton",
    "blockquoteButton",
    "codeButton",
    "bulletListButton",
    "orderedListButton"
  ];

  static values = {
    uploadMaxSizeMb: Number,
    uploadAllowedMimeTypes: String,
    uploadAllowedMimeTypesHumanized: String,
  };

  connect() {
    this.uploadMaxSizeMbValue = this.element.dataset.uploadMaxSizeMb;
    this.uploadAllowedMimeTypesValue = this.element.dataset.uploadAllowedMimeTypes;
    this.uploadAllowedMimeTypesHumanizedValue = this.element.dataset.uploadAllowedMimeTypesHumanized;

    this.editor = new Editor({
      element: this.editorTarget,
      extensions: [
        StarterKit.configure({
          strike: false,
          code: false,
          heading: {
            levels: [1],
          },
        }),
        TextStyle,
        DelMark,
        PreNode,
        Image,
        Link.configure({
          openOnClick: false,
          autolink: true,
          linkOnPaste: true,
          defaultProtocol: "https",
          protocols: ["http", "https"],
        }),
      ],
      content: this.inputTarget.value,
      onUpdate: () => {
        this.inputTarget.value = this.editor.getHTML();
        this.updateToolbarState();
      },
      onSelectionUpdate: () => {
        this.updateToolbarState();
      },
    });
  }

  toggleBold() {
    this.editor.chain().focus().toggleBold().run();
  }

  toggleItalic() {
    this.editor.chain().focus().toggleItalic().run();
  }

  toggleStrike() {
    this.editor.chain().focus().toggleDel().run();
  }

  toggleHeading() {
    this.editor.chain().focus().toggleHeading({ level: 1 }).run();
  }

  toggleBlockquote() {
    this.editor.chain().focus().toggleBlockquote().run();
  }

  toggleCode() {
    this.editor.chain().focus().togglePre().run();
  }

  toggleBulletList() {
    this.editor.chain().focus().toggleBulletList().run();
  }

  toggleOrderedList() {
    this.editor.chain().focus().toggleOrderedList().run();
  }

  toggleUndo() {
    this.editor.chain().focus().undo().run();
  }

  toggleRedo() {
    this.editor.chain().focus().redo().run();
  }

  toggleLinkDropdown() {
    this.linkDropdownTarget.classList.toggle("hidden");
  }

  handleEnterLinkKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
      this.setLink();
    }
  }

  setLink() {
    setLink(this.editor, this.linkInputTarget);
    this.toggleLinkDropdown();
  }

  unsetLink() {
    this.editor.chain().focus().unsetLink().run();
    this.toggleLinkDropdown();
  }

  uploadImage() {
    uploadImage(this.editor, {
      maxSizeMb: this.uploadMaxSizeMbValue,
      allowedMimeTypes: this.uploadAllowedMimeTypesValue,
      allowedMimeTypesHumanized: this.uploadAllowedMimeTypesHumanizedValue,
    });
  }

  updateToolbarState() {
    if (this.boldButtonTarget) {
      const isBoldActive = this.editor.isActive("bold");
      this.boldButtonTarget.classList.toggle("is-active", isBoldActive);
    }
    if (this.italicButtonTarget) {
      const isItalicActive = this.editor.isActive("italic");
      this.italicButtonTarget.classList.toggle("is-active", isItalicActive);
    }
    if (this.strikeButtonTarget) {
      const isStrikeActive = this.editor.isActive("del");
      this.strikeButtonTarget.classList.toggle("is-active", isStrikeActive);
    }
    if (this.linkButtonTarget) {
      const isLinkActive = this.editor.isActive("link");
      this.linkButtonTarget.classList.toggle("is-active", isLinkActive);
      if (isLinkActive) {
        const href = this.editor.getAttributes("link").href;
        this.linkInputTarget.value = href;
      } else {
        this.linkInputTarget.value = '';
      }
    }    
    if (this.headingButtonTarget) {
      const isHeadingActive = this.editor.isActive("heading", { level: 1 });
      this.headingButtonTarget.classList.toggle("is-active", isHeadingActive);
    }
    if (this.blockquoteButtonTarget) {
      const isBlockquoteActive = this.editor.isActive("blockquote");
      this.blockquoteButtonTarget.classList.toggle("is-active", isBlockquoteActive);
    }
    if (this.codeButtonTarget) {
      const isCodeActive = this.editor.isActive("pre");
      this.codeButtonTarget.classList.toggle("is-active", isCodeActive);
    }
    if (this.bulletListButtonTarget) {
      const isBulletListActive = this.editor.isActive("bulletList");
      this.bulletListButtonTarget.classList.toggle("is-active", isBulletListActive);
    }
    if (this.orderedListButtonTarget) {
      const isOrderedListActive = this.editor.isActive("orderedList");
      this.orderedListButtonTarget.classList.toggle("is-active", isOrderedListActive);
    }
  }

  disconnect() {
    this.editor.destroy();
  }
}
