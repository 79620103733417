import "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import * as Util from "./lib/util";
import "@hotwired/turbo-rails";
import "trix";
import "@rails/actiontext";
import "./controllers";
import "./channels";
import "./lib/native/bridge";
import Honeybadger from "@honeybadger-io/js";
import LogRocket from "logrocket";
import "chartkick/chart.js";
import "./globals";

ActiveStorage.start();

document.addEventListener("DOMContentLoaded", () => {
  const hexId = document.body.getAttribute("data-current-user-hex-id");
  const email = document.body.getAttribute("data-current-user-email");
  const plan = document.body.getAttribute("data-current-user-plan");

  if (Util.isProduction()) {
    console.log("booting for production");

    LogRocket.init("rtnuh6/mxu");

    LogRocket.identify(hexId, {
      email,
      plan,
    });

    Honeybadger.setContext({
      user_id: hexId,
      user_email: email,
    });

    Honeybadger.configure({
      apiKey: "hbp_QWyB9lNHp1MTqRWcTPAoY4oT08TIhI3L0jQA",
      environment: "production",
      revision: "initial",
    });

    setTimeout(() => {
      let _hsq = (window._hsq = window._hsq || []);

      if (email) {
        _hsq.push([
          "identify",
          {
            email: email,
          },
        ]);
      }

      document.addEventListener("turbo:load", (event) => {
        let _hsq = (window._hsq = window._hsq || []);

        const url = new URL(event.detail.url);
        const path = url.pathname;
        const searchParams = url.search;
        const fullString = path + searchParams;

        console.dir(`tracking page view ${fullString}`);

        _hsq.push(["setPath", fullString]);
        _hsq.push(["trackPageView"]);
      });
    }, 100);
  } else {
    console.log("booting for development");

    Honeybadger.setContext({
      user_id: hexId,
      user_email: email,
    });

    Honeybadger.configure({
      apiKey: "",
      environment: "development",
      revision: "initial",
    });
  }
});
