// This module handles image uploads for use with the TipTap editor.

import Rails from "@rails/ujs";
import SparkMD5 from "spark-md5";
import Flash from "../../flash";
import Files from "../../files";

export function uploadImage(editor, { maxSizeMb, allowedMimeTypes, allowedMimeTypesHumanized }) {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = 'image/*';
  input.addEventListener('change', async (event) => {
    const file = event.target.files[0];
    if (file) {
      const { success, message } = Files.verifyAttachment(file, {
        fileTypes: allowedMimeTypes,
        fileTypesHumanized: allowedMimeTypesHumanized,
        maxSizeMb: maxSizeMb,
      });

      if (!success) {
        Flash.addFlash(message, "error");
        return;
      }

      try {
        Flash.addFlash("Uploading image, please wait...", "alert");
        await uploadToRails(file, editor);
      } catch (error) {
        console.error("Error during image upload:", error);
        Flash.addFlash("Error uploading image. Please try again.", "error");
      }
    }
  });
  input.click();
}

async function uploadToRails(file, editor) {
  const checksum = await calculateChecksum(file);

  const formData = new FormData();
  formData.append("file", file);
  formData.append("blob[filename]", file.name);
  formData.append("blob[content_type]", file.type);
  formData.append("blob[byte_size]", file.size);
  formData.append("blob[checksum]", checksum);

  const response = await fetch('/rails/active_storage/direct_uploads', {
    method: 'POST',
    headers: {
      'X-CSRF-Token': Rails.csrfToken(),
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error('Failed to get direct upload URL');
  }

  const data = await response.json();
  if (!data.signed_id || !data.direct_upload || !data.direct_upload.url) {
    throw new Error('Invalid response from direct upload URL request');
  }

  const uploadResponse = await fetch(data.direct_upload.url, {
    method: 'POST',
    body: createFileFormData(file, data.direct_upload.headers),
  });

  if (!uploadResponse.ok) {
    throw new Error('Failed to upload file to direct upload URL');
  }

  const uploadData = await uploadResponse.json();
  if (uploadData.url) {
    insertImage(uploadData.url, editor);
    Flash.addFlash("Image uploaded successfully!", "success");
  } else {
    console.error("File upload failed:", uploadData);
    Flash.addFlash("Error uploading image. Please try again.", "error");
  }
}

function createFileFormData(file, headers) {
  const formData = new FormData();
  formData.append("file", file);
  if (headers) {
    Object.entries(headers).forEach(([key, value]) => {
      formData.append(key, value);
    });
  }
  return formData;
}

function insertImage(imageUrl, editor) {
  if (editor) {
    editor.chain().focus().setImage({ src: imageUrl }).run();
  } else {
    console.error("Editor instance is not defined.");
  }
}

async function calculateChecksum(file) {
  const arrayBuffer = await file.arrayBuffer();
  const spark = new SparkMD5.ArrayBuffer();
  spark.append(arrayBuffer);
  return btoa(spark.end(true));
}
