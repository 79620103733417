/**
 * Client side behavior for team member search
 */

import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";
import { enter, leave } from "el-transition";
import FilterToggle from "../../lib/filter_toggle";
import * as Util from "../../lib/util";

export default class extends Controller {
  static targets = [
    "button",
    "filters",
    "query",
    "groupFilterToggle",
    "clearAllFiltersButton",
    "filterGroup",
    "panel",
  ];

  static values = {
    behavior: String,
    apiEndpoint: String,
    scrollSnapId: String,
  };

  /**
   * lifecycle
   */

  connect() {
    this.handleInput = debounce(this.submit.bind(this), 300);
  }

  groupFilterToggleTargetConnected(element) {
    /**
     * Filter toggle buttons should not be checked by default. This prevents caching mismatches where
     * the checked filters don't reflect what's actually on the page because of HTML / Turbo caching.
     */
    element.checked = false;

    FilterToggle.updateFormElement({
      element: this.filtersTarget,
      filterName: element.getAttribute("data-filter-value"),
      enabled: element.checked,
    });

    this.updateFilterGroup(element.getAttribute("data-filter-group-name"));
  }

  searchFilterGroupTargetConnected(element) {
    /**
     * Reset the input on load to avoid any weirdness with browsers
     * retaining previously typed values.
     */
    element.value = "";
  }

  /**
   * actions
   */

  submit(event) {
    if (this.behaviorValue == "api") {
      event?.preventDefault();
      this.element.setAttribute("aria-busy", "true");
      this.handleApiSubmit();
    } else {
      this.buttonTarget.click();
    }
  }

  toggleFilter(event) {
    FilterToggle.toggle(event.currentTarget, this.filtersTarget);
    this.handleInput();
  }

  toggleGroupFilter(event) {
    const checkbox = event.currentTarget;

    FilterToggle.updateFormElement({
      element: this.filtersTarget,
      filterName: checkbox.getAttribute("data-filter-value"),
      enabled: checkbox.checked,
    });

    this.updateFilterGroup(checkbox.getAttribute("data-filter-group-name"));
    this.handleInput();
  }

  clearAllFilters() {
    this.filtersTarget.value = "";

    this.filterGroupTargets.forEach((element) => {
      const name = element.getAttribute("data-filter-group-name");

      this.updateFilterGroup(name);

      element.querySelectorAll("input[type=checkbox]").forEach((checkbox) => {
        checkbox.checked = false;
      });
    });

    if (this.hasQueryTarget) this.queryTarget.value = "";

    this.handleInput();
  }

  togglePanel(event) {
    const element = event.currentTarget;
    const filterGroupName = element.getAttribute("data-filter-group-name");
    const elementToToggle = this.element.querySelector(
      `[data-filter-group-panel-name='${filterGroupName}']`
    );
    const open = elementToToggle.getAttribute("data-open") == "true";
    const path = event.composedPath();
    let clickContainedPanel = path.includes(elementToToggle);
    let clickedContainedSubmitButton = path.includes(this.buttonTarget);

    if (open && !clickContainedPanel && !clickedContainedSubmitButton) {
      leave(elementToToggle).then(() =>
        elementToToggle.setAttribute("data-open", "false")
      );
    } else {
      // close other panels
      this.forceClosePanels();
      enter(elementToToggle).then(() =>
        elementToToggle.setAttribute("data-open", "true")
      );
    }
  }

  closePanels(event) {
    const path = event.composedPath();
    let clickContainedPanel = false;
    let clickedContainedSubmitButton = path.includes(this.buttonTarget);

    this.panelTargets.forEach((panel) => {
      if (path.includes(panel)) clickContainedPanel = true;
    });

    if (!clickContainedPanel && !clickedContainedSubmitButton)
      this.forceClosePanels();
  }

  searchFilterGroup(event) {
    const inputElement = event.currentTarget;
    const searchText = inputElement.value || "";
    const filterGroupName = inputElement.getAttribute("data-filter-group-name");
    const filterGroupRoot = this.getFilterGroupRoot(filterGroupName);

    const optionElements = filterGroupRoot.querySelectorAll(
      "[data-filter-group-option='true']"
    );

    optionElements.forEach((optionElement) => {
      const optionText =
        optionElement.getAttribute("data-filter-option-text") || "";

      if (!searchText || searchText == "") {
        optionElement.classList.toggle("hidden", false);
        return;
      }

      if (optionText.toLowerCase().includes(searchText.toLowerCase())) {
        optionElement.classList.toggle("hidden", false);
      } else {
        optionElement.classList.toggle("hidden", true);
      }
    });
  }

  /**
   * helpers
   */

  updateFilterGroup(name) {
    // ensure the clear all button is visible based on if there's any filter enabled
    this.clearAllFiltersButtonTarget.classList.toggle(
      "hidden",
      !this.hasFilterEnabled
    );

    // ensure that the active indicator is shown based on if any GROUP filters are enabled
    const groupRoot = this.getFilterGroupRoot(name);

    if (groupRoot) {
      const groupFilters = groupRoot
        .getAttribute("data-group-filter-values")
        .split(",");
      groupRoot
        .querySelector("#active_indicator")
        .classList.toggle("hidden", !this.hasAnyFilterEnabled(groupFilters));
    }
  }

  async handleApiSubmit() {
    try {
      let params = new URLSearchParams();

      if (this.hasQueryTarget) params.append("query", this.queryTarget.value);

      if (this.hasFiltersTarget)
        params.append("filter_status", this.filtersTarget.value);

      let url = `${this.apiEndpointValue}?${params.toString()}`;

      const res = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      });

      if (res.ok) {
        const text = await res.text();

        Turbo.renderStreamMessage(text);
        Util.scrollToTopOfWindow();
      } else {
        throw new Error();
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      this.element.setAttribute("aria-busy", "false");
    }
  }

  getFilterGroupRoot(name) {
    return this.filterGroupTargets.find(
      (group) => group.getAttribute("data-filter-group-name") == name
    );
  }

  hasAnyFilterEnabled(filters) {
    const intersection = this.filters.filter((value) =>
      filters.includes(value)
    );
    return intersection.length > 0;
  }

  forceClosePanels() {
    this.panelTargets.forEach((panel) => {
      if (panel.getAttribute("data-open") == "true")
        leave(panel).then(() => panel.setAttribute("data-open", "false"));
    });
  }

  get filters() {
    if (this.hasFiltersTarget) {
      return this.filtersTarget.value.split(",");
    } else {
      return [];
    }
  }

  get filterGroupNames() {
    return this.element.getAttribute("data-filter-group-names").split(",");
  }

  get hasFilterEnabled() {
    return this.hasFiltersTarget && this.filtersTarget.value.length > 1;
  }

  get panelOpen() {
    return this.element.getAttribute("data-panel-open") == "true";
  }
}
