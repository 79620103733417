import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  navigateToRowTarget(event) {
    const rowElement = event.currentTarget;
    const href = rowElement.getAttribute("data-href");

    /**
     * We only want to navigate if we clicked an element that isn't something
     * like a dropdown or other link.
     */
    const shouldPreventNavigation = event
      .composedPath()
      .some(
        (el) =>
          el instanceof HTMLElement &&
          el.getAttribute("data-prevent-navigation") === "true"
      );

    if (!shouldPreventNavigation) {
      Turbo.visit(href, { action: "advance" });
    }
  }
}
